import React, { useEffect, useState } from "react";
import { Service } from "../../../agent";
import { Table, Input, Button, Space, Tag, Spin,Row,Col,Modal ,message,Image} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { HiRefresh } from 'react-icons/hi';
import { BiMessageSquareAdd } from 'react-icons/bi';
import axios, { baseURLImg } from "../../../utils/API";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {  RiArrowGoBackFill} from 'react-icons/ri';
import Cookies from "universal-cookie";
import { SideUserPlace } from "./SideUserPlace";


const MangeServiceMyPlace = () => {

        const cookies = new Cookies();

  const history = useHistory();
  
  const [IdAppPlace, setIdAppPlace] = useState(
    cookies.get("IdAppPlace")
  );

  const GoToMangeCompany = (e) => {
   // localStorage.setItem("IdAppProvince", "");
    history.push("UpdatePlace");
  };

  
  const data = [];
  const [dataProvince, setdataProvince] = useState([]);

  useEffect(() => {
    try {
 
      
      const getcategoryall = async () => {
        const res = await axios.get("/category");
        setdataProvince(res.data.data)
      }
getcategoryall()
      
    } catch (error) {
      
    }
    
  }, []);



  if (dataProvince.length < 0) {
    data.push({
      key: "0",
      no: "0",
      name_ar: "",
      name_en: "",
    
            name_ar: "عروض الفنادق",
            name_en: "Hotel Offer",
            name_ku: "Hotel Offer",
            Icon: "",
            Imagemain: "",
         
    
  })
  }
  else {
     dataProvince.map((res, i) =>
    data.push({
      key: res.Idcategory,
      no: i + 1,
      
      name_ar: res.name_ar,
      name_en: res.name_en,
    
            name_ku: res.name_ku,
            Icon:`${baseURLImg}${res.Icon}`,
            Imagemain: baseURLImg+res.Imagemain
     
    })
  );
  }
 
  const { confirm } = Modal;
  
  async function showConfirm(e) {

    
    setTimeout(async() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: "هل انت متأكد من حذف هذا القسم ؟ ",
        okText:"نعم",cancelText:"لا",
      async  onOk() {
          axios.delete('/category', {
            data: {
              "Idcategory": e.key
            }
            
          });
         
          
          setTimeout(async() => {
            const res = await axios.get("/category");
        setdataProvince(res.data.data)
             message.success("Delete");
           
          }, 1000);
     

      // window.location.reload(false);

            
             
           
        },
        onCancel() {
         
        },
         
      });
       
    },1000);
  
      
   
  }

  const UpdateData =async () => {
    const res = await axios.get("/category");
        setdataProvince(res.data.data);
  };
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      searchInput,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {}}
          Provinceholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
  
    {
      title: "اسم القسم بالعربي",
      dataIndex: "name_ar",
      key: "name_ar",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("name_ar"),
      },
     {
      title: "اسم القسم بالانكليزي",
      dataIndex: "name_en",
      key: "name_en",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("name_en"),
    },
      
      {
      title: "Icon",
      dataIndex: "Icon",
      key: "Icon",

      render: (text, record) => (
        <div style={{ display: "flex" }}>
            <Image style={{ maxHeight: "300px" }} width={"50px"} height={"50px"}
                  src={text} />
      
          </div>
      ),
    },
     {
      title: "الصورة الرئيسية",
      dataIndex: "Imagemain",
      key: "Imagemain",

      render: (text, record) => (
        <div style={{ display: "flex" }}>
            <Image style={{ maxHeight: "300px" }} width={"50px"} height={"50px"}
                  src={text} />
      
          </div>
      ),
    },
     
     
    {
      title: "Manage",
      dataIndex: "Manage",
      key: "Manage",

      render: (text, record) => (
        <div style={{display:"flex"}}>
        <div className="divwsel" onClick={() => UpdateState(record)}>
          <Tag color="green">Update</Tag>
        </div>
         <div className="divwsel" onClick={() => showConfirm(record)}>
          <Tag color="red">Delete</Tag>
          </div>
          </div>
      ),
    },
  ];
 
    const [Places_Imageupload2, setPlaces_Imageupload2] = useState({ file: "" });

  const [Places_Imageupload3, setPlaces_Imageupload3] = useState({ file: "" });

   const [Places_Image, setPlaces_Image] = useState({ file: "" });
  const [Places_Image1, setPlaces_Image1] = useState({ file: "" });
  const [Places_Image2, setPlaces_Image2] = useState({ file: "" });
  const [Places_Image3, setPlaces_Image3] = useState({ file: "" });

  const [Places_Imageupload, setPlaces_Imageupload] = useState({
    file: window.location.origin + "/img/Noimage.jpg",
  });
    
  
    const handleChangeImage = (event) => {
    setPlaces_Imageupload({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image({
      file: event.target.files[0],
    });

  };

  const [Places_Imageupload5, setPlaces_Imageupload5] = useState({
    file: window.location.origin + "/img/Noimage.jpg",
   });


  const [Places_Imageupload1, setPlaces_Imageupload1] = useState({
   file: window.location.origin + "/img/Noimage.jpg",
  });

    const handleChangeImage1 = (event) => {
    setPlaces_Imageupload1({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image1({
      file: event.target.files[0],
    });
  };


    const handleChangeImage2 = (event) => {
    setPlaces_Imageupload2({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image2({
      file: event.target.files[0],
    });
  };

    const handleChangeImage3 = (event) => {
    setPlaces_Imageupload3({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image3({
      file: event.target.files[0],
    });
  };


   //Model Add State

  const [visibleState, setVisibleState] = useState(false);
  const [confirmLoadingState, setConfirmLoadingState] = useState(false);
  const [modalTexStatet, setModalTextState] = useState('Content of the modal');
  const [ModelStateAr, setStateAr] = useState("");
  const [ModelStateArPrice, setStateArPrice] = useState("");
  const [ModelStateArCurncy, setStateArCurncy] = useState('USD');


 
     
  const showModalState = () => {
    setVisibleState(true);
    setStateAr("");
      setStateArPrice("");
      setStateArCurncy("USD");

  };

  const handleOkState = async () => {
    
     if(ModelStateAr==="")
    {
       message.warning("يرجى ادخال اسم القسم بالعربي")
    }
    else if(ModelStateArPrice==="")
    {
      message.warning("يرجى ادخال اسم القسم بالانكليزي")
    }

    else if(Places_Image.file==="")
    {
       message.warning("يرجى اختيار ايقونة القسم")
    }
    else if(Places_Image1.file==="")
    {
       message.warning("يرجى اختيار الصورة الرئيسية للقسم")
    }
    else{

    
      setConfirmLoadingState(true);
    UpdateDetailsPlaceAndUpload3().then(
      () => {
        
    
    setTimeout(async () => {
      
            const res = await axios.get("/category");
        setdataProvince(res.data.data);
      
       setStateAr("");
      setStateArPrice("");
      setStateArCurncy("");
     setVisibleState(false);
      setConfirmLoadingState(false);
      message.success("Add");
      setPlaces_Image({ file:"" });
    setPlaces_Image1({ file:"" });
    setPlaces_Imageupload1({file: window.location.origin + "/img/Noimage.jpg"})
    setPlaces_Imageupload({file: window.location.origin + "/img/Noimage.jpg"})
    }, 1000);
          }
    )
        }
  };

  const handleCancelState = () => {
    setVisibleState(false);
  };


    //Model Update State

  const [visibleState2, setVisibleState2] = useState(false);
  const [confirmLoadingState2, setConfirmLoadingState2] = useState(false);
  const [modalTexStatet2, setModalTextState2] = useState('Content of the modal');
    const [ModelStateAr2, setStateAr2] = useState('');
      const [ModelStateArPrice2, setStateArPrice2] = useState('');
  const [ModelStateArCurncy2, setStateArCurncy2] = useState('USD');
  const [State_ID, setState_ID] = useState('');
  
 const UpdateDetailsPlaceAndUpload2 = (e) => {
   if(Places_Image2.file==="")

   {
    const url = `/category`;
    const formData = new FormData();
    formData.append("document", Places_Imageupload5.file);
    formData.append("document", Places_Image3.file);

   formData.append("Idcategory", State_ID);
   formData.append("name_ar", ModelStateAr2);
   formData.append("name_en", ModelStateArPrice2);
   formData.append("name_ku", "");
   formData.append("change", "imgmain");

    return axios.put(url, formData);
   }
  else if(Places_Image3.file==="")

   {
    const url = `/category`;
    const formData = new FormData();
    formData.append("document", Places_Image2.file);
    formData.append("document", Places_Imageupload5.file);

   formData.append("Idcategory", State_ID);
   formData.append("name_ar", ModelStateAr2);
   formData.append("name_en", ModelStateArPrice2);
   formData.append("name_ku", "");
   formData.append("change", "icon");
    return axios.put(url, formData);
   }
   else{
    const url = `/category`;
    const formData = new FormData();
    formData.append("document", Places_Image2.file);
    formData.append("document", Places_Image3.file);
   formData.append("Idcategory", State_ID);
   formData.append("name_ar", ModelStateAr2);
   formData.append("name_en", ModelStateArPrice2);
   formData.append("name_ku", "");
   formData.append("change", "all");
    return axios.put(url, formData);
   }
  };


   const UpdateDetailsPlaceAndUpload3 = (e) => {
   
      const url = `/category`;
      const formData = new FormData();
      formData.append("document", Places_Image.file);
      formData.append("document", Places_Image1.file);
     
     formData.append("name_ar", ModelStateAr);
     formData.append("name_en", ModelStateArPrice);
     formData.append("name_ku", "");
     formData.append("change", "all");
     
      return axios.post(url, formData);
    
    
  };


  const handleOkState2 = async () => {
        setConfirmLoadingState2(true);
    UpdateDetailsPlaceAndUpload2().then(

      () => {
         
     
        setTimeout(async () => {
      
          const res = await axios.get("/category");
          setdataProvince(res.data.data);
          setStateAr2("");
          setStateArPrice2("");
          setStateArCurncy2("");

          setVisibleState2(false);
          setConfirmLoadingState2(false);
          message.success("Update");
        }, 500);
      }
     )
  
  };

  const handleCancelState2 = () => {
    setVisibleState2(false);
  };

  const UpdateState = (e) => {
    setVisibleState2(true);
    setStateAr2(e.name_ar);
    setStateArPrice2(e.name_en);
    setState_ID(e.key);
    setPlaces_Imageupload2({ file:e.Icon });
    setPlaces_Imageupload3({ file:e.Imagemain });

  };

 

    const titelplace = cookies.get("titelplace")

  return (
     <div style={{ display: "flex", width: "100%" }} >
          <SideUserPlace />
          <div  className="widiv"  style={{ display: "flex",flexDirection:"column"}}>

      {dataProvince.length < 0 && (
        <Spin
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            background: "#ffffffc1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 75454,
          }}
          tip="Loading..."
        ></Spin>
      )}
      <div
        className="dashboard_main"
        style={{ justifyContent: "space-between",background:"rgb(229, 233, 234)",margin:5,padding:"0px 5px" }}
      >
        <div
          style={{ padding: 5, display: "flex", justifyContent: "flex-start" }}
         
        >
          <Tag
            style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="orange"
              onClick={showModalState}
          >
           اضافة قسم جديد
              <BiMessageSquareAdd size={20} />
            
          </Tag>

           <Modal
        title="اضافة قسم جديد"
        visible={visibleState}
        onOk={handleOkState}
        confirmLoading={confirmLoadingState}
        onCancel={handleCancelState}
        okText="Add"
      >
       <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>اسم القسم بالعربي</label>
                  </div>

                  <input
                    className="inputtext"
                    value={ModelStateAr}
                    onChange={(e) => setStateAr(e.target.value)}
                    required
                  />
                </div>
 <div style={{ width: "100%" }}>
                  <div className="lableinput">
                   <label>اسم القسم بالانكليزي</label>
                  </div>

                  <input
                    className="inputtext"
                    value={ModelStateArPrice}
                    onChange={(e) => setStateArPrice(e.target.value)}
                    required
                    
                  />
                </div>
              
               
               <div className="containerimag">
                <span className="lableinput">Icon</span>
                <Image style={{maxHeight:"50px"}} width={"50px"} height={"100%"} src={Places_Imageupload.file} />

                <input
                  type="file"
                  onChange={(e) => handleChangeImage(e)}
                  multiple
                  style={{ background: "#ffeb3b", border: "1px solid black" }}
                  id="fileu7"
                  hidden
                  accept="image/*"
                />
                <label className="selectfile " htmlFor="fileu7">
                  اضغط هنا ليتم اختيار Icon
                </label>
              </div>


             
               <div className="containerimag">
                <span className="lableinput">1الصورة الرئيسية للقسم</span>
                <Image style={{maxHeight:"300px"}} width={"100%"} height={"100%"} src={Places_Imageupload1.file} />

                <input
                  type="file"
                  onChange={(e) => handleChangeImage1(e)}
                  
                  style={{ background: "#ffeb3b", border: "1px solid black" }}
                  id="fileup2"
                  hidden
                  accept="image/*"
                />
                <label className="selectfile " htmlFor="fileup2">
                 اضغط هنا ليتم اختيار الصورة الرئيسية
                </label>
              </div>

             
      </Modal>

            <Modal
        title="تحديث بيانات القسم"
        visible={visibleState2}
        onOk={handleOkState2}
        confirmLoading={confirmLoadingState2}
        onCancel={handleCancelState2}
        okText="Update"
      >
       <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>اسم القسم بالعربي</label>
                  </div>

                  <input
                    className="inputtext"
                    value={ModelStateAr2}
                    onChange={(e) => setStateAr2(e.target.value)}
                    required
                  />
                </div>
 <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>اسم القسم بالانكليزي</label>
                  </div>

                  <input
                    className="inputtext"
                    value={ModelStateArPrice2}
                    onChange={(e) => setStateArPrice2(e.target.value)}
                    required
                    
                  />
                </div>
             
               <div className="containerimag" style={{marginTop:5}}>
                <span className="lableinput">Icon</span>
                <Image style={{maxHeight:"50px"}} width={"50px"} height={"100%"} src={Places_Imageupload2.file} />

                <input
                  type="file"
                  onChange={(e) => handleChangeImage2(e)}
                  multiple
                  style={{ background: "#ffeb3b", border: "1px solid black" }}
                  id="fileup"
                  hidden
                  accept="image/*"
                />
                <label className="selectfile " htmlFor="fileup">
                  اضغط هنا ليتم اختيار Icon
                </label>
              </div>


             
               <div className="containerimag" style={{marginTop:5}}>
                <span className="lableinput">الصورة الرئيسية للقسم</span>
                <Image style={{maxHeight:"300px"}} width={"100%"} height={"100%"} src={Places_Imageupload3.file} />

                <input
                  type="file"
                  onChange={(e) => handleChangeImage3(e)}
                  
                  style={{ background: "#ffeb3b", border: "1px solid black" }}
                  id="fileup3"
                  hidden
                  accept="image/*"
                />
                <label className="selectfile " htmlFor="fileup3">
                 اضغط هنا ليتم اختيار الصورة الرئيسية
                </label>
              </div>

             
      </Modal>
        </div>

        <div
          style={{ padding: 5, display: "flex", justifyContent: "flex-start" }} className="acton"
        >
          <div 
            onClick={UpdateData}
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tag
              style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="orange"
            >
             
              تحديث البيانات
               <HiRefresh size={20} />
            </Tag>
          </div>

          <div
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tag className="Tagclass" style={{ padding: 10, fontWeight: 700 }} color="green">
              العدد الكلي : {data.length}
            </Tag>
          </div>

 <div
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >

         
           </div>
          

        

        </div>

         
        
      </div>

    
     

                

               
      
       <div style={{ width: "100%",padding:"10px 15px",overflow:'auto' }}>
          <Table
            locale
            bordered
            columns={columns}
            dataSource={data.reverse()}
            pagination={{ pageSize: 5 }}
            key={1}
          />
        </div>
     </div>
    </div>
  );
};

export default MangeServiceMyPlace;
