import React, { useEffect, useState } from "react";
import { NotiFiction } from "../../../agent";
import { Table, Input, Button, Space, Tag, Spin,Row,Col,Modal ,message, Image} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { HiRefresh } from 'react-icons/hi';
import { BiMessageSquareAdd } from 'react-icons/bi';
import axios, { baseURLImg } from "../../../utils/API";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {  RiArrowGoBackFill} from 'react-icons/ri';
import Cookies from "universal-cookie";

import moment from "moment";
import { SideUserPlace } from "./SideUserPlace";

const MangeNotifictionMyPlace = () => {
   const FORMAT = "YYYY ddd MMM DD HH:mm";

         const cookies = new Cookies();

   
  const [IdAppPlace, setIdAppPlace] = useState(
    cookies.get("IdAppPlace")
  );
  
  const history = useHistory();
  
  

  const GoToMangeCompany = (e) => {
   // localStorage.setItem("IdAppProvince", "");
    history.push("UpdatePlace");
  };

  
  const data = [];
  const [dataProvince, setdataProvince] = useState([]);

 useEffect(() => {
    try {
      const getallsupuser = async () => {
        const res = await axios.get("/notifiction")
        setdataProvince(res.data.data)
      }
      getallsupuser();
    } catch (error) {
      
    }
    
  }, []);


console.log(dataProvince)
  if (dataProvince.length < 0) {
    data.push({
      key: "0",
      no: "0",
      Noti_Title: "",
       Noti_Subject: "",
        Noti_State: "",
      UserAddNoti: "",
       User_IdAddNoti: "",
    DateAdd: "",
      DateUpdate: "",
      Image: "",
    UserName: "",
      UserEmail:"",
  })
  }
  else {
     dataProvince.map((res, i) =>

    data.push({
      key: res.Idnotifiction,
      no: i + 1,
     Noti_Title: res.Title,
       Noti_Subject: res.Subject,
        Noti_State: res.State,
      UserAddNoti:res.TokenUser,
        User_IdAddNoti: res.UserID,
    DateAdd: moment(res.createdAt).format(FORMAT),
    DateUpdate: moment(res.updatedAt).format(FORMAT),
     Image:baseURLImg+res.Image,
      UserName: "",
      UserEmail: res.users[0].adsfreenumber ,
    })
  );
  }
 
  const { confirm } = Modal;
  
  async function showConfirm(e) {

    
    setTimeout(async() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: "هل انت متأكد من حذف هذة المراجعة ؟ ",
        okText:"نعم",cancelText:"لا",
      async  onOk() {
          axios.delete(`/notifiction/`, {
            data: {

    "Places_IdApp":IdAppPlace,
    "Noti_Id": e.key

            }
            
          });
         
          
          setTimeout(async() => {
               const res = await axios.get("/notifiction")
        setdataProvince(res.data.data)
             message.success("Delete");
           
          }, 1000);
     

      // window.location.reload(false);

            
             
           
        },
        onCancel() {
         
        },
         
      });
       
    },1000);
  
      
   
  }

  const UpdateData =async () => {
         const res = await axios.get("/notifiction")
        setdataProvince(res.data.data)
  };
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      searchInput,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {}}
          Provinceholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
     {
      title: "المستخدم",
      dataIndex: "UserName",
      key: "UserName",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("UserName"),
    },
     {
      title: "ايميل المستخدم",
      dataIndex: "UserEmail",
      key: "UserEmail",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("UserEmail"),
    },
    {
      title: "العنوان",
      dataIndex: "Noti_Title",
      key: "Noti_Title",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("Noti_Title"),
    },
      {
      title: "الموضوع",
      dataIndex: "Noti_Subject",
      key: "Noti_Subject",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("Noti_Subject"),
    },
      
     {
      title: "الصورة",
      dataIndex: "Image",
      key: "Image",

      render: (text, record) => (
        <div style={{ display: "flex" }}>
            <Image style={{ maxHeight: "300px" }} width={"50px"} height={"50px"}
                  src={text} />
      
          </div>
      ),
    },
     {
      title: "الحالة",
      dataIndex: "Noti_State",
      key: "Noti_State",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("Noti_State"),
    },
    ,
     {
      title: "تاريخ الاضافة",
      dataIndex: "DateAdd",
      key: "DateAdd",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("DateAdd"),
    },
   
   
  ];


   //Model Add State

  const [visibleState, setVisibleState] = useState(false);
  const [confirmLoadingState, setConfirmLoadingState] = useState(false);
  const [modalTexStatet, setModalTextState] = useState('Content of the modal');
  const [ModelStateAr, setStateAr] = useState('');



  const showModalState = () => {
    setVisibleState(true);
    setStateAr("");

  };

 const showModalState2 = () => {
    setVisibleState2(true);
   

  };
  const [alluserdata, setalluserdata] = useState([]);
  useEffect(() => {
    const getuser = async () => {
      const getalluser = await axios.get("/users");
    setalluserdata(getalluser.data.data)
    }
    getuser()

  }, [])
  
  const [Title, setTitle] = useState("");
  const [Subject, setSubject] = useState("");
  const [TokenUser, setTokenUser] = useState("");
  const [UserID, setUserID] = useState("");
  
  const handleOkState = async () => {
    setConfirmLoadingState(true);
     
    const url = "/notifiction/sen/alluser/";
    const formData = new FormData();
    formData.append("document", Places_Image.file);

        formData.append("Title", Title);

    
    formData.append("Subject", Subject);
   


    const res = await axios.post(url, formData);
        
      if(res.status===200)
      {

 
      
         const res = await axios.get("/notifiction")
        setdataProvince(res.data.data)
      setStateAr("");
     setVisibleState(false);
      setConfirmLoadingState(false);
      message.success("تم اضافة اشعار لكل المستخدمين بنجاح");
    
    }
      else {
              const res = await axios.get("/notifiction")
        setdataProvince(res.data.data)
      setStateAr("");
     setVisibleState(false);
      setConfirmLoadingState(false);
       message.success("خطا . لم تتم الاضافة يرجى المحاولة مرة اخرى");
    }
 };


 const handleOkState2 = async () => {
    setConfirmLoadingState2(true);
     
    const url = "/notifiction/sen/alluser/oneuser";
    const formData = new FormData();
    formData.append("document", Places_Image.file);

        formData.append("UserID", UserID);
formData.append("Title", Title);
    
    formData.append("Subject", Subject);
   


    const res = await axios.post(url, formData);
        
      if(res.status===200)
      {

 
      
         const res = await axios.get("/notifiction")
        setdataProvince(res.data.data)
     setVisibleState2(false);
      setConfirmLoadingState2(false);
      message.success("تم اضافة اشعار للمستخدم بنجاح");
    
    }
      else {
              const res = await axios.get("/notifiction")
        setdataProvince(res.data.data)
     setVisibleState2(false);
      setConfirmLoadingState2(false);
       message.success("خطا . لم تتم الاضافة يرجى المحاولة مرة اخرى");
    }
  };
  
  const handleCancelState = () => {
    setVisibleState(false);
  };


   const [Places_Image, setPlaces_Image] = useState({ file: "" });

  const [Places_Imageupload, setPlaces_Imageupload] = useState({
   file: window.location.origin + "/img/Noimage.jpg",
  });
    
    const handleChangeImage = (event) => {
    setPlaces_Imageupload({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image({
      file: event.target.files[0],
    });
  };
    //Model Update State

  const [visibleState2, setVisibleState2] = useState(false);
  const [confirmLoadingState2, setConfirmLoadingState2] = useState(false);
  const [modalTexStatet2, setModalTextState2] = useState('Content of the modal');
  const [ModelStateAr2, setStateAr2] = useState('');
  const [State_ID, setState_ID] = useState('');



  

  const handleCancelState2 = () => {
    setVisibleState2(false);
  };

  const UpdateState = (e) => {

   setVisibleState2(true);
    setStateAr2(e.Name);
  setState_ID(e.key);
  };

    const titelplace = cookies.get("titelplace")

  return (
    <div style={{ display: "flex", width: "100%" }} >
          <SideUserPlace />
          <div  className="widiv"  style={{ display: "flex",flexDirection:"column" }}>

      {dataProvince.length < 0 && (
        <Spin
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            background: "#ffffffc1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 75454,
          }}
          tip="Loading..."
        ></Spin>
      )}
      <div
        className="dashboard_main"
        style={{ justifyContent: "space-between",background:"rgb(229, 233, 234)",margin:5,padding:"0px 5px" }}
      >
        <div
          style={{ padding: 5, display: "flex", justifyContent: "flex-start" }}
         
        >
        <Tag
            style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="orange"
              onClick={showModalState}
          >
اضافة اشعار لكل المستخدمين            <BiMessageSquareAdd size={20} />
            
          </Tag>

 <Tag
            style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="blue"
              onClick={showModalState2}
          >
اضافة اشعار لمستخدم واحد           <BiMessageSquareAdd size={20} />
            
            </Tag>
            
           <Modal
        title="اضافة اشعار لكل المستخدمين"
        visible={visibleState}
        onOk={handleOkState}
        confirmLoading={confirmLoadingState}
        onCancel={handleCancelState}
        okText="Add"
      >
       <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>العنوان</label>
                  </div>

                  <input
                    className="inputtext"
                    value={Title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                   
                  />
                </div>

                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>الموضوع</label>
                  </div>

                                    
   <textarea
                    style={{ height: 70 }}
                    className="inputtext"
                        value={Subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                   
                  />
                  
                  
              
              </div>
              

              <div className="containerimag">
                <span className="lableinput">صورة الاشعار</span>
                <Image style={{maxHeight:"300px"}} width={"100%"} height={"100%"} src={Places_Imageupload.file} />

                <input
                  type="file"
                  onChange={(e) => handleChangeImage(e)}
                  multiple
                  style={{ background: "#ffeb3b", border: "1px solid black" }}
                  id="fileup"
                  hidden
                  accept="image/*"
                />
                <label className="selectfile " htmlFor="fileup">
                  اضغط هنا ليتم اختيار الصورة
                </label>
              </div>

             
      </Modal>


<Modal
        title="اضافة اشعار لمستخدم واحد"
        visible={visibleState2}
        onOk={handleOkState2}
        confirmLoading={confirmLoadingState2}
        onCancel={handleCancelState2}
        okText="Add"
            >
               <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>المستخدم</label>
                  </div>

                   <select
                    style={{ padding: 6 }}
                    className="inputtext"
                    type="text"
                     value={UserID}
                    onChange={(e) => setUserID(e.target.value)}
                    required
                    placeholder="Please Enter Role User"
                >
                   <option></option>
                  {alluserdata.map((res, i) =>
                    <option key={i} value={res._id}>{res.firstname + " " + res.lastname + " || " + res.email}</option>
                  )}
                    
                   
                                  </select>

                 
                </div>

       <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>العنوان</label>
                  </div>

                  <input
                    className="inputtext"
                    value={Title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                   
                  />
                </div>

                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>الموضوع</label>
                  </div>

                                    
   <textarea
                    style={{ height: 70 }}
                    className="inputtext"
                        value={Subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                   
                  />
                  
                  
              
              </div>
              

              <div className="containerimag">
                <span className="lableinput">صورة الاشعار</span>
                <Image style={{maxHeight:"300px"}} width={"100%"} height={"100%"} src={Places_Imageupload.file} />

                <input
                  type="file"
                  onChange={(e) => handleChangeImage(e)}
                  multiple
                  style={{ background: "#ffeb3b", border: "1px solid black" }}
                  id="fileup"
                  hidden
                  accept="image/*"
                />
                <label className="selectfile " htmlFor="fileup">
                  اضغط هنا ليتم اختيار الصورة
                </label>
              </div>

             
            </Modal>
            
          
          
        </div>

        <div
          style={{ padding: 5, display: "flex", justifyContent: "flex-start" }} className="acton"
        >
          <div 
            onClick={UpdateData}
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
            >
              
            <Tag
              style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="orange"
            >
             
              تحديث البيانات
               <HiRefresh size={20} />
            </Tag>
          </div>

          <div
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tag  className="Tagclass" style={{ padding: 10, fontWeight: 700 }} color="green">
              العدد الكلي : {data.length}
            </Tag>
          </div>

 <div
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >

         
          
           </div>
          

        

        </div>

         
        
      </div>

    
     

                

               
      
       <div style={{ width: "100%",padding:"10px 15px",overflow:"auto" }}>
          <Table
            locale
            bordered
            columns={columns}
            dataSource={data.reverse()}
            pagination={{ pageSize: 8 }}
            key={1}
          />
        </div>
       </div>
    </div>
  );
};

export default MangeNotifictionMyPlace;
