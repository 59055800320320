import { Col, Row, Image, message, Tag ,Modal,Checkbox} from "antd";
import React, { useEffect, useState } from "react";
import axios, { baseURLImg, IdAppCompany } from "../../../utils/API.js";
import {Category,Users } from "../../../agent";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';


import { RiChatDeleteFill ,RiArrowGoBackFill, RiRoadsterFill, RiSuperscript} from 'react-icons/ri';
import { AiTwotoneSave} from 'react-icons/ai';
import Cookies from "universal-cookie";
import { SideUserPlace } from "./SideUserPlace.js";
import moment from "moment";
const FORMAT = "MM-DD-YYYY  HH:mm";


const Updatausermyplace = () => {

         const cookies = new Cookies();

    const CatPlace = cookies.get("usercat");

  const [IdUser, setIdUser] = useState(cookies.get("userid"));
    const [iduserupdate, setiduserupdate] = useState(cookies.get("iduserupdate"));

    const [IdAppPlace, setIdAppPlace] = useState(
    cookies.get("IdAppPlace")
  );

  function destroyAll() {
  Modal.destroyAll();
}


  const { confirm } = Modal;
  
  function showConfirm() {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: "هل انت متأكد من حذف هذا المستخدم ؟ ",
        okText:"نعم",cancelText:"لا",
        onOk() {
        axios.delete('/users/', {data:{"id":iduserupdate}});
          message.success("Delete");
          cookies.set("IdAppPlace", IdAppPlace);
          setTimeout(() => {
          history.push("MangeUserMyPlace");

          }, 500);
        },
        onCancel() {
         
        },
      });
    },500);
  
  }
  
const history = useHistory();

  
    const [CategoryCompany, setCategoryCompany] = useState([]);
    const [StateCompany, setStateCompany] = useState([]);

    const GoToMangePlaces = (e) => {
    history.push("MangeUserMyPlace");
  };

  



  const [Places_Image, setPlaces_Image] = useState({ file: "" });

  const [PhotoUser, setPhotoUser] = useState({
   file: window.location.origin + "/img/Noimage.jpg",
  });

const [datasupuser, setdatasupuser] = useState([]);

  useEffect(() => {
    try {
      const getallsupuser = async () => {
        const res = await axios.get("/supscriptadsuser")
        setdatasupuser(res.data.data)
      }
      getallsupuser();
    } catch (error) {
      
    }
    
  }, []);

  const [FirstNameUser, setFirstNameUser] = useState("");
  const [LastNameUser, setLastNameUser] = useState("");
  const [Email, setEmail] = useState("");
  const [Passowrd, setPassowrd] = useState("");
  const [Address, setAddress] = useState("");
  const [typeradsres, settyperadsres] = useState("");
  const [typeradsres2, settyperadsres2] = useState("مجاني");
const [adsfreenumber, setadsfreenumber] = useState("");
const [createdAt, setcreatedAt] = useState("");

const [adsfreenumber2, setadsfreenumber2] = useState("");
const [Idsupscripuserads, setIdsupscripuserads] = useState("");


  const [Role, setRole] = useState("User");
  const [Phone, setPhone] = useState("");
  const [TypeUser, setTypeUser] = useState("");
  const [state, setstate] = useState("Active");
  const [UserPermissions, setUserPermissions] = useState({
                "read": "true",
                "add": "true",
                "update": "true",
                "delete": "true"
            });
  const [Places_Imageupload, setPlaces_Imageupload] = useState({
    file: window.location.origin + "/img/Noimage.jpg",
  });
  const UpdateDetailsPlaceAndUpload = (file) => {
    const url = "/users";
    const formData = new FormData();
    formData.append("ImageUser", Places_Image.file);

        formData.append("id", iduserupdate);

    
    formData.append("firstname", FirstNameUser);
    formData.append("lastname", LastNameUser);
    formData.append("email", Email);
    formData.append("phone", Phone);
    formData.append("address", Address);
    formData.append("isadmin", Role==="Admin"?true:false);
    formData.append("typeradsres", typeradsres2);
      formData.append("state", state);
      formData.append("adsfreenumber", adsfreenumber2);
      formData.append("Idsupscripuserads", Idsupscripuserads);
   
      
    return axios.put(url, formData);
  };

  const UpdateRestPass =async () => {
   
    await axios.put("/users/restpwsuserdash",{"Passowrd":Passowrd,"id":iduserupdate});
  };


     useEffect(() => {
    const GetDataPlaces = async (IdAppPlace) => {
      const dataPlace = await axios.get(`/users/all/user/${IdAppPlace}`)

     
      setFirstNameUser(dataPlace.data.data[0].firstname);
      setLastNameUser(dataPlace.data.data[0].lastname);
      setEmail(dataPlace.data.data[0].email);

      setPassowrd(dataPlace.data.data[0].password);
      setAddress(dataPlace.data.data[0].address);
      settyperadsres(dataPlace.data.data[0].typeradsres + " " + `( ${dataPlace.data.data[0].adsfreenumber} أعلان المتبقي )`);
      setRole(dataPlace.data.data[0].isadmin?"Admin":"User");
      
            setadsfreenumber(dataPlace.data.data[0].adsfreenumber);
 setcreatedAt(dataPlace.data.data[0].createdAt);
      setPhone(dataPlace.data.data[0].phone);
settyperadsres2(dataPlace.data.data[0].typeradsres);
      setTypeUser(dataPlace.data.data[0].isadmin);
      setstate(dataPlace.data.data[0].state);

      setPlaces_Imageupload({
        file: `${baseURLImg}${dataPlace.data.data[0].ImageUser}`,
      });
      setPhotoUser({
        file: `${baseURLImg}${dataPlace.data.data[0].ImageUser}`,
      });


    };

    GetDataPlaces(iduserupdate);
     }, [iduserupdate]);
  

  
  const handleChangeImage = (event) => {
    setPhotoUser({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image({
      file: event.target.files[0],
    });
  };

  const UpdateDetailsPlace = async (e) => {
    try {
      if (PhotoUser.file === "") {
      message.error("error");
    } else {
      e.preventDefault();

        if (resetpsw==true) {
            UpdateDetailsPlaceAndUpload(PhotoUser.file).then(
        (response) => {console.log(response)}
          );
          setTimeout(() => {
            UpdateRestPass().then(
        (response) => {console.log(response)}
          );
          }, 500);
         
      message.success("Save");
        }
        else {
              UpdateDetailsPlaceAndUpload(PhotoUser.file).then(
        (response) => {console.log(response)}
      );
      message.success("Save");
        }
  
    }
    } catch (error) {
      console.log(error)
    }
    
  };


//  const [UserPermissions, setUserPermissions] = useState([{read:true,update:true,delete:true,add:true}]);

    //check box auth
    const OnchangeRead = (e) => {
        setUserPermissions({...UserPermissions, read: e })
    }

    const OnchangeDealte = (e) => {
        setUserPermissions({...UserPermissions, delete: e })
    }

    const OnchangeUpdate = (e) => {
        setUserPermissions({...UserPermissions, update: e })
    }

    const OnchangeAdd = (e) => {
        setUserPermissions({...UserPermissions, add: e })
  }
  
  //password reset

  const [resetpsw, setresetpsw] = useState(false)
  const [resetpswText, setresetpswText] = useState("Password Reset")
  const RestPsw =async () => {
    if (resetpsw==false) {
      setresetpsw(true);
    setPassowrd("");
    setresetpswText('Cancel')
    }
    else {
      setresetpsw(false);
      const dataPlace = await Users.GetOneUserByIdUser(iduserupdate);
      setPassowrd(dataPlace.data.data[0].Passowrd);
      setresetpswText('Password Reset')
    }
  

  }


  return (
        <div style={{ display: "flex", width: "100%" }}>
                    <SideUserPlace />
      <div  className="widiv"  style={{ display: "flex",flexDirection:"column" }}>
      <form onSubmit={(e) => UpdateDetailsPlace(e)}>
        
        <div className="dashboard_main" style={{ justifyContent: "center"}}>
          <Row>

              <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",justifyContent:"space-between",background:"rgb(229 233 234)"
              }}
              xs={24}
              md={24}
              lg={24}
              xl={24}
            >
              <button   style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }} className="btnsavecompany" type="submit">
                حفظ
                <AiTwotoneSave size={20} />
              </button>

              <div className="acton"  style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",justifyContent:"space-between"
              }}>
               
                   <Tag
               onClick={showConfirm}
                             style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}

              className="Tagclass"
              color="blue"
            >
              
                  اشتراك المستخدم
                    <RiSuperscript size={20} style={{margin:"0 5px 0 0"}}/>
                  </Tag>

                 <Tag
                    onClick={() => {
                      cookies.set("IDuserads", iduserupdate);
history.push("MangeFloorMyPlaceUser");
               }}
                             style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}

              className="Tagclass"
              color="orange"
            >
              
                  اعلانات المستخدم
                    <RiRoadsterFill size={20} style={{margin:"0 5px 0 0"}}/>
                  </Tag>
                  
               <Tag
               onClick={showConfirm}
                             style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}

              className="Tagclass"
              color="red"
            >
              
                  حذف
                    <RiChatDeleteFill size={20} style={{margin:"0 5px 0 0"}}/>
            </Tag>

              <Tag
               onClick={GoToMangePlaces}
               style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="green"
            >
                 الرجوع الى اعدادت المستخدمين
                   <RiArrowGoBackFill size={20} style={{margin:"0 5px 0 0"}}/>
                </Tag>
                </div>
            </Col>
            <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              xs={24}
              md={24}
              lg={18}
              xl={20}
            >
              <Col
                style={{
                  padding: 5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>الاسم الاول</label>
                  </div>

                  <input
                    className="inputtext"
                    type="text"
                    value={FirstNameUser}
                    onChange={(e) => setFirstNameUser(e.target.value)}
                    required
                    placeholder="ُEnter FirstNameUser"
                  />
                </div>
              </Col>

              <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>الاسم الاخير</label>
                  </div>

                  <input
                    className="inputtext"
                    type="text"
                    value={LastNameUser}
                    onChange={(e) => setLastNameUser(e.target.value)}
                    required
                    placeholder="Please Enter LastNameUser"
                  />
                </div>
              </Col>

              <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>البريد الالكتروني</label>
                  </div>

                  <input
                    className="inputtext"
                    type="text"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Please Enter Email"
                  />
                </div>
              </Col>

              <Col
                style={{
                  padding: 5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>رقم الهاتف</label>
                  </div>

                  <input
                    
                    className="inputtext"
                    type="number"
                    value={Phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                      placeholder="Please Enter Phone"
                      maxLength="11"
                  />
                </div>
              </Col>

              




              <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>كلمة المرور</label>
                    <label className="restbtn" onClick={RestPsw}>{resetpswText}</label>
                  </div>

                  <input
                    disabled={!resetpsw}
                    className="inputtext"
                    type="password"
                    value={Passowrd}
                    onChange={(e) => setPassowrd(e.target.value)}
                    required
                    placeholder="Please Enter Password"
                  />
                </div>
              </Col>

                          <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>العنوان</label>
                  </div>

                  <input
                    className="inputtext"
                    type="text"
                    value={Address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    placeholder="Please Enter Address"
                  />
                </div>
                          </Col>
                          
                            <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label> نوع الاشتراك الحالي</label>
                  </div>

                    <input
                      disabled
                    className="inputtext"
                    type="text"
                    value={typeradsres}
                    onChange={(e) => settyperadsres(e.target.value)}
                    required
                    placeholder="Please Enter typeradsres"
                    />

                   
                   
                </div>
                </Col>
                
                          <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>صلاحية المستخدم</label>
                  </div>

                   <select
                    style={{ padding: 6 }}
                    className="inputtext"
                    type="text"
                    value={Role}
                    onChange={(e) => setRole(e.target.value)}
                    required
                    placeholder="Please Enter Role User"
                  >
                    <option>Admin</option>
                    <option>User</option>
                                  </select>

                 
                </div>
              </Col>
             

                <Col
                style={{
                  padding: 5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>حالة المستخدم</label>
                  </div>
                  <select
                    style={{ padding: 6 }}
                    className="inputtext"
                    type="text"
                    value={state}
                    onChange={(e) => setstate(e.target.value)}
                    required
                    placeholder="Please Enter state"
                  >
                    <option>Active</option>
                    <option>InActive</option>
                  </select>
                </div>
                          </Col>

       
          

                          <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={24}
                lg={24}
                xl={24}
              >
                <label style={{
                      background:"#86f287",
                      padding: 10,
                      fontWeight: "bold"
                }}>لتغير تفاصيل الاشتراك من لوحة التحكم </label>
              </Col>
            
                 <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>الاشتراك</label>
                  </div>

                   <select
                    style={{ padding: 6 }}
                    className="inputtext"
                    type="text"
                    value={typeradsres2}
                    onChange={(e) =>
                      {
                       settyperadsres2(e.target.value)
                       setIdsupscripuserads(e.target.value==="مجاني" ?  "": datasupuser.filter(res=>res.category===e.target.value).map(res=>res.Idsupscripuserads))
                       setadsfreenumber2(e.target.value==="مجاني" ?14 :datasupuser.filter(res=>res.category===e.target.value).map(res=>res.day))

                      
                     
                    }
                      }
                    required
                    placeholder="Please Enter typeradsres2 User"
                  >
                      <option value={"مجاني"}>مجاني</option>
                      {datasupuser.map((res, i) =>
                        <option key={i} value={res.category}>{res.category}</option>
                      )}
                    
                                  </select>

                 
                </div>
                </Col>
                 <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={4}
                lg={4}
                xl={4}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>عدد وحدات الاشتراك</label>
                  </div>

                    <input
                      
                    className="inputtext"
                    type="number"
                    value={typeradsres2==="مجاني"?14:datasupuser.filter(res=>res.category===typeradsres2).map(res=>res.day)}
                  
                    onChange={(e) => setadsfreenumber2(e.target.value)}
                    required
                  
                    />

                   
                   
                </div>
                </Col>

                            <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={6}
                lg={6}
                xl={6}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>Idsup</label>
                  </div>

                    <input
                      disabled
                    className="inputtext"
                    type="text"
                    value={typeradsres2==="مجاني"?"":datasupuser.filter(res=>res.category===typeradsres2).map(res=>res.Idsupscripuserads)}

                   onChange={(e) => setIdsupscripuserads(e.target.value)}

                    required
                   
                    />

                   
                   
                </div>
                </Col>

                <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={6}
                lg={6}
                xl={6}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>createdAt</label>
                  </div>

                    <input
                      disabled
                    className="inputtext"
                    type="text"
                    value={moment(createdAt).format(FORMAT) }
                   
                    required
                   
                    />

                   
                   
                </div>
                </Col>


</Col>
            <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
              xs={24}
              md={18}
              lg={4}
              xl={4}
            >
              <div className="containerimag">
                <span className="lableinput">صورة المستخدم</span>
                <Image style={{maxHeight:"300px"}} width={"100%"} height={"100%"} src={PhotoUser.file} />

                <input
                  type="file"
                  onChange={(e) => handleChangeImage(e)}
                  multiple
                  style={{ background: "#ffeb3b", border: "1px solid black" }}
                  id="fileup"
                  hidden
                  accept="image/*"
                />
                <label className="selectfile " htmlFor="fileup">
                  اضغط هنا ليتم اختيار الصورة
                </label>
              </div>
            </Col>
         
          
          </Row>
        </div>
      </form>
      </div>
       </div>
  );
};

export default Updatausermyplace;
