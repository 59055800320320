import React, { useEffect, useState } from 'react'
import { SideUserPlace } from './SideUserPlace'
import moment from 'moment';
import { Places,Category,StateContry,Reservation,Review,Floor,Users,NotiFiction,Offer,ImagePlacs,Service,Rooms } from "../../../agent";

import Cookies from "universal-cookie/es6";
import { Avatar, Badge,Popover,Table, Input, Button, Space, Tag, Spin,Row,Col,Modal ,message,Select,Image} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import axios,{ baseURLImg } from '../../../utils/API';
import { useHistory } from "react-router-dom";
import { AiFillSetting } from 'react-icons/ai';
import { IoNotifications} from 'react-icons/io5';
import { FaAlignCenter, FaUserCheck, FaServicestack, FaRestroom, FaShoppingBag, FaImages, FaAddressCard, FaUser } from 'react-icons/fa';
const DashUserPlace = () => {
       const FORMAT = "YYYY ddd MMM DD HH:mm";

  const cookies = new Cookies();
  const history = useHistory();

    



    const [IdUser, setIdUser] = useState(cookies.get("userid"));
  const [IdAppPlace, setIdAppPlace] = useState(cookies.get("IdAppPlace"));
 

  const data = [];
  const data1 = [];

  const [dataProvince, setdataProvince] = useState([]);
  const [dataProvince1, setdataProvince1] = useState([]);

  
  const [ReserLength, setReserLength] = useState(0);
  const [ReviewLength, setReviewLength] = useState(0);
  const [NotiLength, setNotiLength] = useState(0);
const [OfferLength, setOfferLength] = useState(0);
const [FloorLength, setFloorLength] = useState(0);
const [UsersLength, setUsersLength] = useState(0);

const [RoomLength, setRoomLength] = useState(0);
const [ServiceLength, setServiceLength] = useState(0);
const [ImagesLength, setImagesLength] = useState(0);

/*
 useEffect(() => {
 
    Rooms.GetAllRooms(IdAppPlace).then(res=>setRoomLength(res.data.data[0].Rooms.length))
   Service.GetAllService(IdAppPlace).then(res=>setServiceLength(res.data.data[0].Services.length));
  ImagePlacs.GetAllImageImagePlacs(IdAppPlace).then(res=>setImagesLength(res.data.data[0].ImagesPlaces.length));


  Reservation.GetAllReservation(IdAppPlace).then(res=>setReserLength(res.data.data[0].Reservations.length))
   Review.GetAllReviewplace(IdAppPlace).then(res=>setReviewLength(res.data.data[0].Reviews.length));
  Floor.GetAllFloor(IdAppPlace).then(res=>setFloorLength(res.data.data[0].Floor.length));

   Offer.GetAllOfferOnePlace(IdAppPlace).then(res=>setOfferLength(res.data.data.length));


 Users.GetAllUsersInplace(IdAppPlace,IdUser).then(res=>setUsersLength(res.data.data.length));
 NotiFiction.GetAllNotiOnePace(IdAppPlace).then(res=>setNotiLength(res.data.data[0].Notification.filter(ress=>ress.Noti_State==="NoRead").length));
Reservation.GetAllReservation(IdAppPlace).then(res=> setdataProvince1(res.data.data[0].Reservations))
 }, [IdAppPlace])
  */
   useEffect(() => {
     const GetAdsAlluser = async () => {
       const res = await axios.get("/ads/getall");
       const reszain = await axios.get("/supscriptadsuser/zaincash/getall");
       setdataProvince(reszain.data.data)
       setdataProvince1(res.data.data)
       setReserLength(res.data.data.length)
       const resuser = await axios.get("/users");
      setUsersLength(resuser.data.data.length)

         const resads = await axios.get("/ads/getall");
      setFloorLength(resads.data.data.length)

         const rescategory = await axios.get("/category");
      setRoomLength(rescategory.data.data.length)

       

       
         const resnotifiction = await axios.get("/notifiction");
      setNotiLength(resnotifiction.data.data.length)
     }
     GetAdsAlluser();
   })


   
  if (dataProvince.length < 0) {
    data.push({
      key: "0",
      no: "0",
      UserID: "",
      IdOrderSup: "",
    
            Idsupscripuserads: "عروض الفنادق",
            amount: "Hotel Offer",
            to: "Hotel Offer",
            referenceNumber: "",
            createdAt: "",
         
      updatedAt: "",
              idzain: "",
      state: "",
      supscripuserads: [],
            users:[],usersemail:"",usersname:""
  })
  }
  else {
     dataProvince.map((res, i) =>
    data.push({
      key: res.IdOrderSup,
      no: i + 1,
    
      UserID: res.UserID,
      IdOrderSup: res.IdOrderSup,
    
            Idsupscripuserads: res.Idsupscripuserads,
            amount: res.amount,
            to: res.to,
            referenceNumber: res.referenceNumber,
            createdAt:moment(res.createdAt).format(FORMAT) ,
         
      updatedAt:moment( res.updatedAt).format(FORMAT),
              idzain: res.idzain,
      state: res.state,
      supscripuserads: res.supscripuserads[0].category,
      users: res.users[0].firstname + " " + res.users[0].lastname,
      usersemail: res.users[0].email,
     usersname: res.users[0].typeradsres
     
    })
  );
  }

   if (dataProvince1.length < 0) {

    
     
    data1.push(
      {
        "key": "0",
      "no": "0",
            "_id": "6177cca987b4c43b52ed77a6",
            "UserID": "6175b32c1d1e800fa6a641ab",
            "NameAds": "شركة محمود للسياحة",
            "Descreption": "سفرة تجوال لمدة اسبوع داخل العراق",
            "CategoryAds": "عروض السفر",
            "CategoryAdsEn": "Travel Offer",
            "PriceAds": "450,000",
            "createdAt": "2021-10-26T09:38:49.494Z",
            "CurncyAds": "IQD",
            "State": "Active",
            "IdAds": "d66e50e5-53dd-40de-936d-917417b459f7",
            "isSpecial": "false",
            "address": "بغداد السيدية",
            "phone": "07588598788",
            "isNewOffer": true,
            "imageads": [
                {
                    "_id": "6177cca987b4c43b52ed77a7",
                    "State": "Active",
                    "url": "1635241129359-IMG_20210922_133934.jpg",
                    "IdAds": "d66e50e5-53dd-40de-936d-917417b459f7",
                    "UserID": "6175b32c1d1e800fa6a641ab",
                    "createdAt": "2021-10-26T09:38:49.495Z",
                    "updatedAt": "2021-10-26T09:38:49.495Z",
                    "__v": 0
                }
            ],
            "users": [
                {
                    "_id": "6175b32c1d1e800fa6a641ab",
                    "state": "active",
                    "ImageUser": "NoImage.jpg",
                    "isadmin": false,
                    "typeradsres": "مجاني",
                    "whdatads": "10",
                    "firstname": "محمود",
                    "lastname": "علي",
                    "phone": "",
                    "address": "",
                    "email": "saeedharby.83@gmail.com",
                    "Idsupscripuserads": "",
                    "createdAt": "2021-10-24T19:25:32.105Z",
                    "updatedAt": "2021-10-26T09:38:51.554Z",
                    "__v": 0
                }
            ]
     }
    )
  }
  else {
    dataProvince1.map((res, i) =>
      data1.push({
        key: res.IdAds, username: res.users[0].firstname + " " + res.users[0].lastname
        , ...res, createdAt: moment(res.createdAt).format(FORMAT),isSpecial:res.isSpecial?"نعم":"لا"
      })
    );
    }
    
  
    const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      searchInput,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {}}
          Provinceholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  
   //Model Add State

  const [visibleState, setVisibleState] = useState(false);
  const [confirmLoadingState, setConfirmLoadingState] = useState(false);
  const [modalTexStatet, setModalTextState] = useState('Content of the modal');
  const [ModelStateAr, setStateAr] = useState('');
  const [ModelStateEn, setStateEn] = useState('');
  const [ModelStateKu, setStateKu] = useState('');


  const GoToAddUser = () => {
    history.push("AddUserToPlace");
   

  };


  const handleCancelState = () => {
    setVisibleState(false);
  };


    //Model Update State

  const [visibleState2, setVisibleState2] = useState(false);
  const [confirmLoadingState2, setConfirmLoadingState2] = useState(false);
  const [modalTexStatet2, setModalTextState2] = useState('Content of the modal');
  const [ModelStateAr2, setStateAr2] = useState('');
  const [ModelStateEn2, setStateEn2] = useState('');
  const [ModelStateKu2, setStateKu2] = useState('');
  const [State_ID, setState_ID] = useState('');



 


  const handleCancelState2 = () => {
    setVisibleState2(false);
  };

  const UpdateState = (e) => {
    history.push("UpdateUserAll");
    cookies.set("userid",e.key)
  };

  const [datalike, setdatalike] = useState([[{ UserName: "", DateLike: "",UserNameAddComment:"",Comment_Object:"" }]])

  const GetLikePost = (e) => {
    setdatalike(data.filter(ref => ref.key === e.key).map(res => res.Post_Like1))
    setVisibleState(true)
  }
 const GetCommentPost = (e) => {
    setdatalike(data.filter(ref => ref.key === e.key).map(res => res.Posts_Comment1))
    setVisibleState2(true)
  }

   const GoToNoti = (e) => {
  

     NotiFiction.ReadNotiplace(IdAppPlace);
     setTimeout(async () => {
      
  history.push("MangeNotifictionMyPlace");
        localStorage.setItem("indexclass",9)
    }, 500);
  };

  const columns = [
  
    
    {
   title: " اسم المستخدم",
   dataIndex: "users",
   key: "users",
   render: (text) => <div>{text}</div>,
   ...getColumnSearchProps("users"),
 },
    
  {
   title: "ايميل المستخدم",
   dataIndex: "usersemail",
   key: "usersemail",
   render: (text) => <div>{text}</div>,
   ...getColumnSearchProps("usersemail"),
 },
    
   {
   title: "فئة الاشتراك",
   dataIndex: "supscripuserads",
   key: "supscripuserads",
render: (text) => <div>{text}</div>,
   ...getColumnSearchProps("supscripuserads"),
 
 },
  {
   title: "المبلغ IQD",
   dataIndex: "amount",
   key: "amount",
render: (text) => <div>{text}</div>,
   ...getColumnSearchProps("amount"),
 
 
 },
   
   
       {
   title: "حالة الدفع",
   dataIndex: "state",
   key: "state",
render: (text) => <div>{text}</div>,
   ...getColumnSearchProps("state"),
 
 
 },
   {
   title: "تاريخ المعاملة",
   dataIndex: "createdAt",
   key: "createdAt",
render: (text) => <div>{text}</div>,
   ...getColumnSearchProps("createdAt"),
 
 
 },
 {
  title: "Manage",
  dataIndex: "Post_Image",
  key: "Post_Image",

  render: (text, record) => (
    <div style={{ display: "flex" }}>
   
    <div className="divwsel" onClick={() => 
    {
      history.push("ManageZaincash");
    }

    }>
      <Tag color="red">ادارة</Tag>
    </div>
   
     

  
      </div>
  ),
},

];

    const columns1 = [
   
    {
      title: "المستخدم",
      dataIndex: "username",
      key: "username",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("username"),
    },
   {
      title: "اسم الاعلان",
      dataIndex: "NameAds",
      key: "NameAds",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("NameAds"),
        },
    {
      title: "اعلان مميز",
      dataIndex: "isSpecial",
      key: "isSpecial",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("isSpecial"),
      },
    
    
     {
      title: "السعر",
      dataIndex: "PriceAds",
      key: "PriceAds",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("PriceAds"),
    },
    {
      title: "العملة",
      dataIndex: "CurncyAds",
      key: "CurncyAds",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("CurncyAds"),
      },

     {
      title: "القسم",
      dataIndex: "CategoryAds",
      key: "CategoryAds",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("CategoryAds"),
    },
      {
      title: "تاريخ الاعلان",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("createdAt"),
    },
    {
      title: "حالة الاعلان",
      dataIndex: "State",
      key: "State",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("State"),
      },
     {
      title: "Manage",
      dataIndex: "Post_Image",
      key: "Post_Image",

      render: (text, record) => (
        <div style={{ display: "flex" }}>
       
        <div className="divwsel" onClick={() => 
        {
          cookies.set("IdAds",record.key)
          history.push("Updateads");
        }

        }>
          <Tag color="red">ادارة</Tag>
        </div>
       
         

      
          </div>
      ),
    },
    
  ];

    


  // end table reservation users 
    return (
        <div style={{display:"flex",width:"100%"}}>
            <SideUserPlace />
            <div className="widiv" style={{padding:"10px 20px"}}>
             <div className="SideUserPlace_Body_Box">

         

                     <div
              className=" boxmain_dashplace SideUserPlace_Body_Boxmain "
              onClick={()=>history.push("MangeFloorMyPlace")}
            >
              <div className="divmain_dashplace">
                <FaShoppingBag size={30} style={{ color: "rgb(17 46 178)" }}/>
                <span  style={{ fontSize: 20, color: "rgb(17 46 178)",fontWeight: 700 }}>الأعلانات</span>
              </div>
              <div style={{ fontSize: 35, color: "#fff", fontWeight: 600 }}>
                {ReserLength}
              </div>
           
            </div>

              <div
              className=" boxmain_dashplace SideUserPlace_Body_Boxmain " style={{ background: "linear-gradient(45deg,#dcd4ff21, rgb(32 159 216))" }}
                onClick={()=>history.push("MangeUserMyPlace")}
            >
              <div className="divmain_dashplace" >
                <FaUser size={30} style={{ color: "rgb(17 46 178)" }}/>
                <span  style={{ fontSize: 20, color: "rgb(17 46 178)",fontWeight: 700 }}>المستخدمين</span>
              </div>
              <div style={{ fontSize: 35, color: "#fff", fontWeight: 600 }}>
                {UsersLength}
              </div>
           
            </div>


             <div
              className=" boxmain_dashplace SideUserPlace_Body_Boxmain "
              style={{background: "linear-gradient(45deg, rgb(255 255 255 / 13%), rgb(255 210 2))"}}
           onClick={()=>history.push("MangeAllRoom")}
           >
              <div className="divmain_dashplace">
                <FaAddressCard size={30} style={{ color: "rgb(17 46 178)" }}/>
                <span  style={{ fontSize: 20, color: "rgb(17 46 178)",fontWeight: 700 }}>الأشتراكات</span>
              </div>
              <div style={{ fontSize: 35, color: "#fff", fontWeight: 600 }}>
                {FloorLength}
              </div>
           
            </div>

             <div
              className=" boxmain_dashplace SideUserPlace_Body_Boxmain "
              style={{ background: "linear-gradient(45deg, rgb(255 255 255 / 13%),rgb(255 50 90))" }}
                 onClick={()=>history.push("MangeServiceMyPlace")}
            >
              <div className="divmain_dashplace">
                <AiFillSetting size={30} style={{ color: "rgb(17 46 178)" }}/>
                <span  style={{ fontSize: 20, color: "rgb(17 46 178)",fontWeight: 700 }}>الاقسام</span>
              </div>
              <div style={{ fontSize: 35, color: "#fff", fontWeight: 600 }}>
                {RoomLength}
              </div>
           
            </div>


             <div
              className=" boxmain_dashplace SideUserPlace_Body_Boxmain "
              style={{ background: "linear-gradient(45deg, rgb(255 255 255 / 13%),rgb(192 75 255))" }}
                 onClick={GoToNoti}
            >
              <div className="divmain_dashplace">
                <IoNotifications size={30} style={{ color: "rgb(17 46 178)" }}/>
                <span  style={{ fontSize: 20, color: "rgb(17 46 178)",fontWeight: 700 }}>الاشعارات</span>
              </div>
              <div style={{ fontSize: 35, color: "#fff", fontWeight: 600 }}>
                {NotiLength}
              </div>
           
            </div>


          </div>

         



             <div className="chartone" style={{width:"95%",margin:10,overflow:"auto"}}>
                              <h5>اعلانات المستخدمين</h5>    
        <Table
            locale
            bordered
            columns={columns1}
            dataSource={data1}
            pagination={{ pageSize: 5 }}
            key={1}
            
          />   
                  </div>

                  <div className="chartone" style={{width:"95%",margin:10,overflow:"auto"}}>
                              <h5>اشتراكات المستخدمين</h5>    
        <Table
            locale
            bordered
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 5 }}
            key={1}
            
          />   
                  </div>

            </div>
        </div>
    )
}

export default DashUserPlace
